<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 업무일지 결과 목록 -->
          <c-table
            ref="table"
            title="업무일지 결과 목록"
            :columns="grid.columns"
            :data="safetyCheck.safetyCheckResultModelList"
            :gridHeight="grid.height"
            rowKey="safetyCheckResultId"
            :editable="editable&&!disabled"
            :columnSetting="false"
            :filtering="false"
            :usePaging="false"
            @headLinkClick="headLinkClick"
          >
            <!-- <template slot="suffixTitle">
              <font style="font-size:0.8em;font-weight:300;">
                <q-chip color="green" />
                  {{ '개선 건 없음' }}
                <q-chip color="red" />
                  {{ '개선 1건 이상' }}
              </font>
            </template> -->
            <template slot="prefixContent">
              <font v-if="!safetyCheck.plantCd || !safetyCheck.checkDate || !safetyCheck.checkTypeCd" color="#C10015">
                <!-- ※ 사업장, 구분 및 점검년월을 선택하세요. -->
                {{'※ 사업장, 구분 및 점검년월을 선택하세요.'}}
              </font>
              <!-- <font v-if="this.data.safetyCheckId" color="#03d1fd">
                ※ 각 항목 클릭시 개선요청이 가능합니다.
              </font> -->
            </template>
            <template v-slot:customArea="{ props, col }">
              <template v-if="props.row[col.key] === 'MSR0000001'">
                  <!-- class="cursor-pointer" 
                  @click="openPop(col, props.row)"  -->
                <q-icon 
                  name="radio_button_unchecked" 
                  :color="customColor(col, props.row)"/>
              </template>
              <template v-else-if="props.row[col.key] === 'MSR0000002'">
                  <!-- class="cursor-pointer" 
                  @click="openPop(col, props.row)" -->
                <q-icon 
                  name="close" 
                  :color="customColor(col, props.row)" />
              </template>
            </template>
            <!-- 버튼 영역 -->
          </c-table>
        </div>  
      </div>
      <c-dialog :param="popupOptions" :returnData="{ col1: safetyCheck.safetyCheckId }">></c-dialog>
    </q-form>
  </div>
</template>
<script>
export default {
  name: 'safety-check-result',
  props: {
    safetyCheck: {
      type: Object,
      default: () => ({
        safetyCheckId: '',  // 열화점검 일련 번호
        plantCd: '',  // 사업장 코드
        checkStatusCd: '',  // 계획수립/점검중/점검완료
        checkDeptCd: '',  // 점검부서
        checkUserId: '',  // 점검자 ID
        checkDate: '',  // 점검월
        safetyCheckName: '',  // 점검명
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드

        safetyCheckResultModelList: [], // sheet
        safetyCheckUserModelList: [],
      }),
    },
    grid: {
      type: Object,
      default: () => ({
        height: 'auto',
        columns: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    isOld: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editable: true,
      currentMonth: '',
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      this.currentMonth= this.$comm.getToday().slice(-5, this.$comm.getToday().length -3);
      // list setting
    },
    /* eslint-disable no-unused-vars */
    headLinkClick(props, col, index) {
      if (this.isOld) {
        let day = String(col.label).length === 1 ? '0' + col.label : col.label
        // '일별 안점점검 결과 상세 ' + this.currentMonth + '-' + day + '(' + col.label + ')'; 
        this.popupOptions.title = '일별 업무일지 결과 상세 ' + this.currentMonth + '-' + day
        this.popupOptions.target = () => import(`${"./safetyCheckChecking.vue"}`);
        this.popupOptions.param = {
          col: col,
          safetyCheck: this.safetyCheck,
        };
        this.popupOptions.visible = true;
        this.popupOptions.isFull = false;
        this.popupOptions.width = '70%';
        this.popupOptions.closeCallback = this.closeResultPopup;
      } else {
        // 해당 버튼을 누른경우는 필수요소들이 다 있는 경우임으로 더 이상의 유효성평가는 진행 X
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '입력한 정보를 저장한 후 진행가능합니다.\n저장하시겠습니까?', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$emit('save')
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    closeResultPopup(returnData) {
      this.$refs['table'].selected = []
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (returnData.col1) {
        this.safetyCheck.safetyCheckId = returnData.col1;
        this.$emit('setKey', returnData.col1)
      } else {
        this.$emit('getDetail')
      }
    },
    // openPop(col, row) {
    //   this.popupOptions.param = {
    //     safetyCheckId: this.safetyCheck.safetyCheckId,
    //     safetyCheckResultId: row.safetyCheckResultId,
    //     checkItemName: row.checkItemName,
    //     key: col.key,
    //     checkStatusCd: this.safetyCheck.checkStatusCd,
    //     sysApprovalRequestId: this.safetyCheck.sysApprovalRequestId,
    //   };
    //   // col.day + '일(' + col.label + ')' + ' 개선 상세'
    //   this.popupOptions.title = this.$comm.getLangLabel('LBL0002214', {s1: col.day, s2: col.label})
    //   this.popupOptions.target = () =>
    //     import(`${"./safetyCheckImprPop.vue"}`);
    //   this.popupOptions.visible = true;
    //   this.popupOptions.isFull = false;
    //   this.popupOptions.width = '70%';
    //   this.popupOptions.closeCallback = this.closeResultPopup;
    // },
    customColor(col, row) {
      let tableKey = this.$_.split(row['tableKeys'], ',');
      let color = 'green';
      if (tableKey && tableKey.length > 0) {
        this.$_.forEach(tableKey, item => {
          if (item === col.key) {
            color = 'red'
          } 
        })
      }
      return color;
    },
  }
};
</script>
